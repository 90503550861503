<template>
  <section>

    <h2 class="mb-5">Impostazione licenze</h2>
    <b-card v-if="pricing && pricing[0]">
      <h2>Account Trial</h2>
      <b-table-simple striped>
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Installatore</b-th>
            <b-th>Manutentore</b-th>
            <b-th>Utilizzatore</b-th>
            <b-th>Installatore & Manutentore</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>Numero progetti nuovi</b-th>
            <b-td><input class="form-control" v-model="pricing[0].project_limit_type_newimplant_installatore"></b-td>
            <b-td><input class="form-control" v-model="pricing[0].project_limit_type_newimplant_manutentore"></b-td>
            <b-td><input class="form-control" v-model="pricing[0].project_limit_type_newimplant_utilizzatore"></b-td>
            <b-td><input class="form-control"
                v-model="pricing[0].project_limit_type_newimplant_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Numero progetti manutenzione</b-th>
            <b-td><input class="form-control" v-model="pricing[0].project_limit_type_mantenance_installatore"></b-td>
            <b-td><input class="form-control" v-model="pricing[0].project_limit_type_mantenance_manutentore"></b-td>
            <b-td><input class="form-control" v-model="pricing[0].project_limit_type_mantenance_utilizzatore"></b-td>
            <b-td><input class="form-control"
                v-model="pricing[0].project_limit_type_mantenance_installatore_manutentore"></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="5" class="text-right">
              <b-button @click="savePricing(pricing[0])" variant="primary">Salva</b-button>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>

    </b-card>

    <b-card v-if="pricing && pricing[1]">
      <h2>Abbonamento mensile</h2>
      <b-table-simple striped>
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Installatore</b-th>
            <b-th>Manutentore</b-th>
            <b-th>Utilizzatore</b-th>
            <b-th>Installatore & Manutentore</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>Numero progetti nuovi</b-th>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_newimplant_installatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_newimplant_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_newimplant_utilizzatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_newimplant_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Numero progetti manutenzione</b-th>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_mantenance_installatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_mantenance_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_mantenance_utilizzatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].project_limit_type_mantenance_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Prezzo listino</b-th>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[1].price_type_installatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[1].price_type_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[1].price_type_utilizzatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].price_type_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Sconto</b-th>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[1].discount_type_installatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[1].discount_type_manutentore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[1].discount_type_utilizzatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[1].discount_type_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Prezzo finale</b-th>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[1], 'installatore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[1], 'manutentore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[1], 'utilizzatore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[1], 'installatore_manutentore')"></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="5" class="text-right">
              <b-button @click="savePricing(pricing[1])" variant="primary">Salva</b-button>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>

    </b-card>

    <b-card v-if="pricing && pricing[2]">
      <h2>Abbonamento annuale</h2>
      <b-table-simple striped>
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Installatore</b-th>
            <b-th>Manutentore</b-th>
            <b-th>Utilizzatore</b-th>
            <b-th>Installatore & Manutentore</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>Numero progetti nuovi</b-th>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_newimplant_installatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_newimplant_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_newimplant_utilizzatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_newimplant_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Numero progetti manutenzione</b-th>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_mantenance_installatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_mantenance_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_mantenance_utilizzatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].project_limit_type_mantenance_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Prezzo listino</b-th>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[2].price_type_installatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[2].price_type_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[2].price_type_utilizzatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].price_type_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Sconto</b-th>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[2].discount_type_installatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[2].discount_type_manutentore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[2].discount_type_utilizzatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[2].discount_type_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Prezzo finale</b-th>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[2], 'installatore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[2], 'manutentore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[2], 'utilizzatore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[2], 'installatore_manutentore')"></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="5" class="text-right">
              <b-button @click="savePricing(pricing[2])" variant="primary">Salva</b-button>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>

    </b-card>

    <b-card v-if="pricing && pricing[3]">
      <h2>Piano a consumo</h2>
      <b-table-simple striped>
        <b-thead>
          <b-tr>
            <b-th></b-th>
            <b-th>Installatore</b-th>
            <b-th>Manutentore</b-th>
            <b-th>Utilizzatore</b-th>
            <b-th>Installatore & Manutentore</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th>Numero progetti nuovi</b-th>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_newimplant_installatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_newimplant_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_newimplant_utilizzatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_newimplant_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Numero progetti manutenzione</b-th>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_mantenance_installatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_mantenance_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_mantenance_utilizzatore"></b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].project_limit_type_mantenance_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Prezzo listino</b-th>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[3].price_type_installatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[3].price_type_manutentore"></b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[3].price_type_utilizzatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].price_type_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Sconto</b-th>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[3].discount_type_installatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[3].discount_type_manutentore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control" v-model="pricing[3].discount_type_utilizzatore">
            </b-td>
            <b-td><input type="number" step="2" class="form-control"
                v-model="pricing[3].discount_type_installatore_manutentore"></b-td>
          </b-tr>
          <b-tr>
            <b-th>Prezzo finale</b-th>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[3], 'installatore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[3], 'manutentore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[3], 'utilizzatore')"></b-td>
            <b-td><input readonly type="number" step="2" class="form-control"
                :value="finalPrice(pricing[3], 'installatore_manutentore')"></b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="5" class="text-right">
              <b-button @click="savePricing(pricing[3])" variant="primary">Salva</b-button>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>
  </section>
</template>

<script>
import { Requests } from "@/api/requests.js";
import { BCard, BCardText, BButton, BTableSimple, BThead, BTbody, BTh, BTd, BTr, BTfoot } from "bootstrap-vue";

export default {
  components: {
    BCard, BCardText, BButton, BTableSimple, BThead, BTbody, BTh, BTd, BTr, BTfoot
  },

  data: () => ({
    pricing: [],
  }),

  created() {

    this.getLicenses();
  },

  methods: {
    savePricing: async function (plan) {
      const fd = new FormData();
      for (const k of Object.keys(plan)) {
        fd.append(k, plan[k]);
      }
      try {
        await Requests.saveAdminLicenses(fd);
        this.$swal('OK', 'I dati del piano sono stati salvati', 'success');
      } catch(e) {
        this.$swal('Attenzione', 'Si è verificato un errore', 'error');
      }
    },
    price: function (plan, code) {
      return Number(plan["price_type_" + code]);
    },
    discount: function (plan, code) {
      return Number(plan["discount_type_" + code]);
    },
    finalPrice: function (plan, code) {
      return this.price(plan, code) * (100 - this.discount(plan, code)) / 100;
    },

    async getLicenses() {
      try {
        const response = await Requests.getAdminLicences();
        this.pricing = response.data;
      } catch {
        console.log(err);
      }
    },


  },
};
</script>